<template>
	<div class="container">
		<div class="code">
			<span class="title">欧莱雅-现金封包录入系统</span>
			<input type="text" v-model="userName" pattern="[0-9]*" placeholder="请输入姓名" />
			<input type="text" v-model="userWorkNo" placeholder="请输入工号" />
			<input type="text" v-model="shopsNo" placeholder="请输入门店编号" />

			<div class="cbtn">
				<button class="btn">需要申请企业微信权限</button>
			</div>
		</div>
		<Loading :isLoad="isLoad"></Loading>
	</div>
</template>
<script>
export default {
	data() {
		return {
			code: this.$route.query.code,
			userName: '',
			userWorkNo: '',
			shopsNo: '',
			redirect: '/',
			wxId:'',
			isLoad:false
		}
	},
	created(){
		// localStorage.token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ0aGlzIGlzIENBU0gtT0xZIHRva2VuIiwiYXVkIjoiTUlOSUFQUCIsImlzcyI6IkNBU0gtT0xZIiwiZXhwIjoxNjMwMjI3MzIyLCJ1c2VySWQiOiIxNzc1OTIxNjUxNSIsImlhdCI6MTYyOTc5NTMyMn0.Fm4OznSpOtBxv7MHEK4DMvvb5bKoXmW_txyKhniymww'
		// this.$router.replace({ path: this.redirect});
		this.wxLogin()
	},
	methods: {
		wxLogin(){
			if (this.isLoad)
				return
			this.isLoad = !this.isLoad
			if(this.code && this.code != localStorage.code){
				localStorage.code = this.code
				this.axios.post('/api/cash/weixin/toWxId',{
					code: this.code
				}).then((res) => {
					this.isLoad = !this.isLoad
					if(res.data && res.data.token){
						localStorage.token = res.data.token
						this.$router.replace({ path: this.redirect});
					}else{
						this.$notify({
							type: 'warning',
							message: res.msg,
						});
					}
				}).catch(() => {
					this.isLoad = !this.isLoad
				})
			}else{
				this.axios.post('/api/cash/weixin/getAppId').then((res) => {
					this.isLoad = !this.isLoad
					// console.log(res.data);
					let redirectUri = encodeURIComponent("https://o.dianmic.cn/login/login.html?action=get");
					let url_domain = res.data.appDomain || "https://open.weixin.qq.com";
					// console.log(res.data.appDomain);
					// console.log(url_domain);
					let url = url_domain + "/connect/oauth2/authorize?appid=" + res.data.appId 
					+ '&redirect_uri=' + redirectUri + '&response_type=code&scope=snsapi_base&agentid=1000058&state=#wechat_redirect';
					location.href = url
					// console.log(url);
				}).catch(() => {
					this.isLoad = !this.isLoad
				})
			}
		},
		sumbit() {
			this.userName = this.userName.trim();
			this.userWorkNo = this.userWorkNo.trim();
			this.shopsNo = this.shopsNo.trim();
			if(!this.userName){
				this.$notify({
					type:'warning',
					message: "请输入姓名"
				})
				return
			}
			if(!this.userWorkNo){
				this.$notify({
					type:'warning',
					message: "请输入工号"
				})
				return
			}
			if(!this.shopsNo){
				this.$notify({
					type:'warning',
					message: "请输入门店编号"
				})
				return
			}
			if(this.isLoad)
				return
			
			this.isLoad = !this.isLoad
			this.axios.post('/api/cash/login',{
				userName: this.userName,
				userWorkNo: this.userWorkNo,
				shopsNo: this.userWorkNo
			}).then(res => {
				this.isLoad = !this.isLoad
				if (res.code == 0) {
					localStorage.token = res.data.token
					localStorage.shopsName = res.data.shopsName
					localStorage.shopsNo = res.data.shopsNo
					localStorage.userName = res.data.userName
					this.$notify({
						type:'success',
						message: '登录成功'
					})
					this.$router.push('/')
				}else{
					this.$notify({
						type:'warning',
						message: res.msg
					})
				}
			})
		}
	}
}
</script>
<style scoped>
.code {
	display: block;
	width: 80%;
	margin: 0 auto;
	margin-top: 5rem;
	margin-bottom: 2.5rem;
	line-height: 2.5rem;
}
.code input {
	width: 100%;
	margin-bottom: 1rem;
	height: 2rem;
	line-height: 2rem;
}
.title {
	display: block;
	margin-bottom: 2rem;
	font-size: 0.9rem;
	justify-content: center;
	text-align: center;
}
.cbtn {
	display: flex;
	justify-content: center;
}

</style>
